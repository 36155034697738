import styled from 'styled-components';
import quillCSS from 'react-quill/dist/quill.snow.css';
const TextAreaContainer = styled.div`
  height: 100%;
  display: flex;
  margin-top: 35px;
  overflow: hidden;
  .text-display,
    textarea {
    width: 100%;
    padding: 16px 0 16px 16px;
    color: #ffffff;
    background: transparent;
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: hidden;
    display: flex;
    resize: none;
    z-index: 2;
    text-align: left;
    -webkit-user-select: none;
    -webkit-user-drag: none;
    -webkit-app-region: no-drag;
    transform: translate3d(0, 0, 0);
    cursor: auto;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 39px;
    white-space: pre-wrap;
    &::placeholder {
      color: #fff;
    }
  }

  .text-display {
    padding-right: 10px;
  }

  .ql-editor::-webkit-scrollbar {
    width: 10px;
    display: block;
  }

  .ql-editor::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 6px;
  }

  .ql-editor::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px;
  }

  .ql-editor::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }

  .text-box.hidden,
  .text-display.hidden {
    display: none;
  }

  .paste-menu {
    width: 108px;
    height: 70px;
    display: grid;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    position: absolute;
    background: #ffffff;
    cursor: pointer !important;
    border-radius: 4px;
    z-index: 100;
    font-size: 14px;
    font-weight: 600;
    color: #021731;
    border: 1px solid rgba(2, 23, 49, 0.2);
    overflow: hidden;
    padding: 4px 8px;
  }
  .paste-menu > p {
    margin: 0px;
    width: 108px;
  }
  .paste-menu > p:not(:first-child) {
    border-top: 1px solid #000;
    padding-top: 7px;
  }

  .paste-menu > p:hover {
    background: red;
  }
  .quill > .ql-container > .ql-editor.ql-blank::before {
    font-size: 17px; !important
    position: absolute !important;
  }
`;
const StyledReactQuill = styled.div`
  ${quillCSS}
  width: 100%;
  height: 100%;
  // background-color: #24364c;
  .ql-container {
    border: none !important;

  }
  .ql-editor {
    height: calc(100vh - 95px) !important;
  }
  .ql-formats {
    margin-right: 0 !important;
    position: relative;
    top: -15px;
  }
  .ql-formats .ql-underline {
    margin-right: 0;
  }
  .ql-stroke {
    stroke: white !important;
    position: relative;
    height: 2px;
  }
  .ql-fill {
    fill: white !important;
  }
  .overlay:before {
    content: '';
    position: absolute;
    top: 0;
    width: 200%;
    height: 100%;
  }
  .ql-editor.ql-blank::before {
    color: white !important;
    font-style: normal !important;
  }
  .ql-tooltip {
    display: none !important;
  }
  .ql-editor > p > a {
    color: inherit !important;
  }
  .ql-editor ol,
  .ql-editor ul {
    padding-left: 0.25em;
  }
  .ql-toolbar {
    border-radius: 0px 0px 0px 0px !important;
    background: #5954a4 !important;
    border: 0px !important;
    display: block;
    height: 37px !important;
    color: white !important;
    position: fixed;
    align-items: center;
    
  }
    @media (max-width: 600px) {
      .ql-toolbar {
        display: none;
      }
    }

    @media (min-width: 768px) {
    .ql-toolbar {
      position: fixed;
      left: 56%;
      top: calc(100vh - 60px) !important;
    }
  }
  @media (max-width: 280px) {
    .ql-toolbar {
      display: none;
    }
  }
 @media (max-width: 768px) {
    .ql-toolbar {
      left: 56%;
      top: 84%;
      width: 240px;
      padding :0;
    }
  }
  @keyframes toolbar-appear-animation {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .toolbar-pointer-revers::after {
    transform: rotate(180deg) !important;
    top: unset !important;
    bottom: 100% !important;
  }
  .ql-formats {
    vertical-align: unset !important;
    position: relative;
  }

  .ql-formats:nth-child(2) {
    padding-left: 6px;
    padding-right: 6px;
  }

  .ql-formats:nth-child(3) {
    padding-left: 6px;
    padding-right: 6px;
  }

  .ql-formats::before {
    content: '';
    position: absolute;
    left: -1px;
    top: 50%;
    transform: translateY(-50%);
    height: 80%;
    width: 1px;
    background-color: #8ad5eb3d;
  }
  .ql-formats:nth-child(1)::before {
    display: none;
  }
  .ql-formats:nth-child(2)::before {
    height: 20px;
    margin-top: 7px;
  }

  .ql-formats:nth-child(1) > .ql-active > svg > path,
  .ql-formats:nth-child(2) > .ql-active > svg > path {
    stroke: #8ad5eb !important;
  }
  .ql-snow .ql-picker-options {
    background-color: #021731;
    text-align: center;
  }
  .ql-toolbar.ql-snow .ql-color-picker .ql-picker-item:hover {
    border-color: white;
  }
  .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    border-color: #021731;
  }
  .ql-picker-options:nth-child(2) {
    margin-left: -124px !important;
  }
  .ql-snow .ql-picker.ql-expanded .ql-picker-options {
    margin-top: -165px;
  }
  .ql-snow.ql-toolbar button svg,
  .ql-snow .ql-toolbar button svg,
  .ql-snow .ql-color-picker .ql-picker-label svg,
  .ql-snow .ql-icon-picker .ql-picker-label svg {
    float: none !important;
  }
  .ql-toolbar.ql-snow {
    padding: 6px 8px !important;
    font-size: 22px !important;
    color: white;
  }
  .ql-color-picker.ql-background .ql-picker-item:last-child {
    background-color: #021731 !important;
    border: 1px solid #04254e;
    border-radius: 4px;
    width: 136px;
    height: 24px;
    line-height: 24px;
    font-size: 13px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    &:before {
      content: 'Reset';
      position: relative;
      top: -2px;
      color: white;
    }
    &:hover {
      border-color: white;
    }
  }
  .ql-color-picker .ql-picker-item:last-child {
    background-color: #021731 !important;
    border: 1px solid #04254e;
    border-radius: 4px;
    width: 136px;
    height: 24px;
    line-height: 24px;
    font-size: 13px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    &:before {
      content: 'Reset';
      position: relative;
      top: -2px;
      color: white;
    }
    &:hover {
      border-color: white;
    }
  }
  .control-item{
      transition: all 0.3s ease-in-out;
      &:hover {
        // transform: scale(1.1);
        z-index: 999 !important;
      }
    }
    .hidden {
      opacity: 0;
      transition: opacity 0.5s ease;
      z-index: -1;
    }
  }
  .readonly-mode {
  opacity: 0.5 !important;
  pointer-events: none;
}

`;

export { TextAreaContainer, StyledReactQuill };
