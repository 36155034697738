import { SET_OVERLAY_MODE } from '../actionConstants';

/**
 * Function name: setOverlayModeAction
 * Description: Creates an action to set the overlay mode state in the Redux store.
 * @param {boolean} payload - The value to set for the overlay mode state.
 * @returns {Object} - Redux action object with the type and payload.
 */
export const setOverlayModeAction = (payload) => {
  return {
    type: SET_OVERLAY_MODE,
    payload,
  };
};
