import React from "react";
import ErrorIcon from "../images/error-16.svg";

export default function TeamsErrorPagePlaceHolder() {
  return (
    <div
      style={{
        position: "absolute",
        pointerEvents: "none",
        color: "white",
        backgroundColor: "#04254e",
        width: "100%",
        height: "100%",
      }}
      className="placeHolder"
    >
      <div
        style={{
          position: "relative",
          display: "flex",
          marginTop: "18%",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "20px",
        }}
      >
        <img style={{ marginLeft: "-5px" }} src={ErrorIcon} alt="Error Icon" />
        <div style={{ marginLeft: "-10px", fontWeight: "bold" }}>
          Something went wrong
        </div>
      </div>

      <div
        style={{
          marginTop: "1%",
          fontSize: "15px",
          textAlign: "center",
        }}
      >
        Please reload or reinstall your VODIUM Teams App
      </div>
    </div>
  );
}
