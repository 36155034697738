import { SET_SCROLL_SPEED } from '../actionConstants';

/**
 * Function name: setScrollSpeedAction
 * Description: Action creator to set the scroll speed in the Redux store.
 * @param {number} payload - The value to set for the scroll speed.
 * @returns {Object} - Redux action object with the type and payload.
 */
export const setScrollSpeedAction = (payload) => {
  return {
    type: SET_SCROLL_SPEED,
    payload,
  };
};
