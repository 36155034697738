import { SET_SCROLL_DISTANCE } from '../actionConstants';

/**
 * Function name: setScrollDistanceAction
 * Description: Action creator to set the scroll distance in the Redux store.
 * @param {number} payload - The value to set for the scroll distance.
 * @returns {Object} - Redux action object with the type and payload.
 */
export const setScrollDistanceAction = (payload) => {
  return {
    type: SET_SCROLL_DISTANCE,
    payload,
  };
};
