import { SET_SETTINGS_OPEN } from '../actionConstants';

/**
 * Function name: setSettingsOpenAction
 * Description: Action creator to set the settings open state in the Redux store.
 * @param {boolean} payload - The value to set for the settings open state.
 * @returns {Object} - Redux action object with the type and payload.
 */
export const setSettingsOpenAction = (payload) => {
  return {
    type: SET_SETTINGS_OPEN,
    payload,
  };
};
