import { SET_FILE_TITLE } from '../actionConstants';

/**
 * Function name: setFileTitleAction
 * Description: Action creator function to set the file title.
 * @param {string} payload - The new title of the file.
 * @returns {Object} - Redux action object with the type and payload.
 */
export const setFileTitleAction = (payload) => {
  return {
    type: SET_FILE_TITLE,
    payload,
  };
};
