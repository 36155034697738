import { SET_LICENSEKEY } from '../actionConstants';

/**
 * Function name: setLicenseKeyAction
 * Description: Creates an action to set the license key in the Redux store.
 * @param {string} payload - The value to set for the license key.
 * @returns {Object} - Redux action object with the type and payload.
 */
export const setLicenseKeyAction = (payload) => {
  return {
    type: SET_LICENSEKEY,
    payload,
  };
};
