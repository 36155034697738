import {
  VODIUM_SIGN_IN,
  VODIUM_AUTH_WEB_TOKEN,
  VODIUM_WEB_APP,
  VODIUM_SECRET_PASS,
} from "../firebase-config";
import { userAuthentication } from "../api/api";
import CryptoJS from "crypto-js";

/**
 * Function name: refreshPage
 * Description: Refreshes the current page.
 */
export const refreshPage = () => {
  window.location.href = VODIUM_WEB_APP;
};

/**
 * Function name: redirectSignIn
 * Description: Redirects the user to the sign-in page and removes the authentication token from localStorage.
 */
export const redirectSignIn = () => {
  localStorage.removeItem(VODIUM_AUTH_WEB_TOKEN);
  window.location.href = VODIUM_SIGN_IN; //Comment this out when running the app locally
};

/**
 * Function name: redirectError
 * Description: Redirects the user to the error page and removes the authentication token from localStorage.
 */
export const redirectError = () => {
  localStorage.removeItem(VODIUM_AUTH_WEB_TOKEN);
  window.location.href = VODIUM_WEB_APP + `error`;
};

/**
 * Function name: authenticate
 * Description: Authenticates the user by verifying the token and redirecting to sign-in if necessary.
 * @param {string} idToken - The token to authenticate.
 * @returns {string} - The email of the authenticated user.
 */
const authenticate = async (idToken) => {
  console.log("token", idToken);
  // const tokenss = localStorage.getItem('VODIUM_AUTH_WEB_TOKEN');
  try {
    if (idToken) {
      const email = await verifyUser(idToken);
      console.log("Authenticate Email", email);
      refreshPage();
      return email;
    } else {
      const token = localStorage.getItem(VODIUM_AUTH_WEB_TOKEN);
      console.log("Authenticate Token", token);
      if (token) {
        await verifyUser(token);
        console.log("VERIFY TOKEN", token);
      } else {
        console.log("authenticate else");
        redirectSignIn();
      }
    }
  } catch (error) {
    console.log("Authenticate error :", error);
  }
};

/**
 * Function name: verifyUser
 * Description: Verifies the user using the given token.
 * @param {string} token - The token to verify.
 * @returns {string} - The email of the verified user.
 */
const verifyUser = async (token) => {
  console.log("Verify User Token", token);
  try {
    const result = await userAuthentication(token);
    console.log("RESULT VERIFYUSER", result);
    window.localStorage.setItem(VODIUM_AUTH_WEB_TOKEN, token);
    console.log("Verify Token Result", result);
    return result;
  } catch (error) {
    // redirectSignIn();
    redirectError();
    console.log("verify catch");
    throw error;
  }
};

/**
 * Function name: dateValid
 * Description: Checks if the date is valid based on the token.
 * @param {string} fromToken - The date from the token.
 * @returns {boolean} - True if the date is valid, false otherwise.
 */
const dateValid = (fromToken) => {
  try {
    let date1 = new Date(fromToken);
    let date2 = new Date(getCurrentDateTimeUTC());

    date2.setHours(date2.getHours() - 1);

    var time1 = date1.getTime();
    var time2 = date2.getTime();

    if (time1 < time2) {
      return false;
    } else if (time1 >= time2) {
      return true;
    }
  } catch (e) {
    return false;
  }
};

/**
 * Function name: getCurrentDateTimeUTC
 * Description: Gets the current date and time in UTC format.
 * @returns {string} - The current date and time in UTC format.
 */
const getCurrentDateTimeUTC = () => {
  const date = new Date();
  var day = date.getUTCMonth() + 1;
  return (
    [
      date.getFullYear(),
      day < 10 ? "0" + day : day, //0 = January, 1 = February, etc.
      date.getUTCDate(),
    ].join('/')
  );
}

/**
 * Function name: decryptData
 * Description: Decrypts the data using the secret pass.
 * @param {string} tokenValue - The value of the token to decrypt.
 * @returns {Object} - The decrypted data object.
 */
const decryptData = (tokenValue) => {
  try {
    const bytes = CryptoJS.AES.decrypt(tokenValue, VODIUM_SECRET_PASS);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (e) {
    return null;
  }
};

/**
 * Function name: validFromTeamsApp
 * Description: Checks if the token is valid from the Teams app.
 * @param {string} tokenValue - The value of the token to check.
 * @returns {boolean} - True if the token is valid from Teams app, false otherwise.
 */
export const validFromTeamsApp = (tokenValue) => {
  var data = decryptData(tokenValue);
  return dateValid(data);
};

export default authenticate;
