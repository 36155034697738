import { SET_PROMPTER_ON } from '../actionConstants';

/**
 * Function name: setPrompterOnAction
 * Description: Action creator to set the prompter state in the Redux store.
 * @param {boolean} payload - The value to set for the prompter state.
 * @returns {Object} - Redux action object with the type and payload.
 */
export const setPrompterOnAction = (payload) => {
  return {
    type: SET_PROMPTER_ON,
    payload,
  };
};
