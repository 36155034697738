import { SET_IS_EDIT_MODE } from '../actionConstants';

/**
 * Function name: setIsEditModeAction
 * Description: Creates an action to set the 'edit mode' state in the Redux store.
 * @param {boolean} payload - The value to set for the 'edit mode' state.
 * @returns {Object} - The action object.
 */
export const setIsEditModeAction = (payload) => {
  return {
    type: SET_IS_EDIT_MODE,
    payload,
  };
};
