import React from "react";
import "./css/spinner.css";

export default function LoadingSpinner() {
  return (
    <div
      style={{
        position: "absolute",
        pointerEvents: "none",
        color: "white",
        backgroundColor: "#04254e",
        width: "100%",
        height: "100%",
      }}
      className="spinner-container"
    >
      <div className="loading-spinner"></div>
      <div className="loading-text">Please wait...</div>
    </div>
  );
}
