import { SET_ADVANCE_SCROLL } from '../actionConstants';

/**
 * Function name: setAdvanceScrollAction
 * Description: Creates an action to set the advance scroll.
 * @param {Object} payload - The payload to set.
 * @returns {Object} - The action object.
 */
export const setAdvanceScrollAction = (payload) => {
  return {
    type: SET_ADVANCE_SCROLL,
    payload,
  };
};
