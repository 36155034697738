import { SET_IS_IN_MOBILE } from '../actionConstants';

/**
 * Function name: setIsInMobileAction
 * Description: Action creator to set the 'is in mobile' state.
 * @param {boolean} payload - The value to set for the 'is in mobile' state.
 * @returns {Object} - Redux action object with the type and payload.
 */
export const setIsInMobileAction = (payload) => {
  return {
    type: SET_IS_IN_MOBILE,
    payload,
  };
};
