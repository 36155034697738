import React, { useState, useEffect } from 'react';
import './LicenseKey.scss';
import VodiumLogo from '../images/logo.svg';
import { activateKeyService } from '../api/api';
import './variables.css';
import { useNavigate } from 'react-router-dom';

/**
 * Function name: renderAlert
 * Description: Renders an alert based on the provided API response.
 * @param {string} apiResponse - The API response.
 * @returns {JSX.Element|null} - The alert component.
 */
const renderAlert = (apiResponse) => {
  switch (apiResponse) {
    case 'KEY_ACTIVATED':
      return (
        <div className="alert success-alert">
          <p className="pstyle">Activation Success</p>
        </div>
      );
    case 'KEY_NOT_EXISTS':
      return (
        <div className="alert danger-alert">
          <p className="pstyle">License key not exist</p>
        </div>
      );
    case 'SUBSCRIPTION_NO_LONGER_ACTIVE':
      return (
        <div className="alert warning-alert">
          <p className="pstyle">Subscription no longer active</p>
        </div>
      );
    case 'LIMIT_REACHED':
      return (
        <div className="alert danger-alert">
          <p className="pstyle">Limit reached</p>
        </div>
      );
    case 'KEY_ALREADY_USED':
      return (
        <div className="alert warning-alert">
          <p className="pstyle">Key already in used</p>
        </div>
      );
    case 'KEY_ARCHIVED':
      return (
        <div className="alert warning-alert">
          <p className="pstyle">Key archived</p>
        </div>
      );
    case 'KEY_INVALID':
      return (
        <div className="alert danger-alert">
          <p className="pstyle">Please enter a valid key</p>
        </div>
      );
    default:
      return null;
  }
};

/**
 * Function name: LicenseKey
 * Description: Manages the activation of a license key.
 * @returns {JSX.Element} - The license key component.
 */
const LicenseKey = () => {
  const [inputKey, setInputKey] = useState('');
  const [isInputValid, setInputValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isActivated, setIsActivated] = useState(false);
  const [apiResponse, setAPIResponse] = useState(null);
  const navigate = useNavigate();
  const handleInputChange = (event) => {
    let value = event.target.value.toUpperCase();
    if (value) {
      value = value
        .replace(/-/g, '')
        .match(/.{1,4}/g)
        .join('-');
    }

    setInputKey(value);
  };

  /**
   * Function name: handleInputClick
   * Description: Handles input click events.
   */
  const handleInputClick = () => {
    setInputValid(true);
  };

  /**
   * Function name: handleSubmit
   * Description: Handles form submission events.
   */
  const handleSubmit = async () => {
    try {
      if (!inputKey) {
        setInputValid(false);
        setAPIResponse('KEY_INVALID');
        return;
      }
      setIsLoading(true);
      const keyStatus = await activateKeyService(inputKey);
      // console.log('KeyStatus', keyStatus.message);

      //update api response and render the alert based on return response 
      setAPIResponse(keyStatus.message);

      //clear API response after 2 seconds
      setTimeout(() => {
        setAPIResponse(null);
      }, 2000);

      if (keyStatus.message === 'KEY_ACTIVATED') {
        setIsActivated(true);
        setTimeout(() => {
          navigate('/scriptwindow');
        }, 2000);
      } else {
        setIsActivated(false);
      }
    } catch (error) {
      console.log('Error Activating the key:', error);
    } finally {
      setIsLoading(false);
    }

    console.log('Input key:', inputKey);
  };

  const CheckifKeyIsValid = () => {
    return (
      <div className="alert danger-alert">
        <p>Please enter a valid key</p>
      </div>
    );
  };
  useEffect(() => {
    document.body.classList.add('license-key-background');

    return () => {
      document.body.classList.remove('license-key-background');
    };
  }, []);

  return (
    <div className="license-key">
      <img className="w-auto h-8" src={VodiumLogo} alt="Vodium Logo" />
      <p className="pstyle2">Enter License Key</p>
      {!isInputValid && <CheckifKeyIsValid />}
      <input
        className={`${isActivated ? 'input-box activated' : 'input-box'} ${
          isInputValid ? '' : 'invalid'
        }`}
        disabled={isLoading} // Disable input when loading
        type="text"
        id="key"
        name="key"
        placeholder="XXXX-XXXX-XXXX-XXXX-XXXX-XXXX"
        onChange={handleInputChange}
        onClick={handleInputClick}
        onKeyPress={(event) => /[0-9a-zA-Z-]/i.test(event.key)}
        onPaste={(event) => {
          event.preventDefault();
          const text = event.clipboardData.getData('text/plain');
          const sanitizedText = text.replace(/[^0-9a-zA-Z-]/g, '');
          setInputKey(sanitizedText);
        }}
        value={inputKey}
        maxLength="29"
      />
      <button className="button-33" onClick={handleSubmit} disabled={isLoading}>
        {isLoading ? 'Loading...' : 'Activate'}
      </button>
      {apiResponse && renderAlert(apiResponse)}
    </div>
  );
};

export default LicenseKey;
