import { initializeApp } from 'firebase/app';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyAKPPXxJ1i-X4qK-b-QmeAWYVBkpZ3LpvA',
  authDomain: 'vodium-test.firebaseapp.com',
  projectId: 'vodium-test',
  storageBucket: 'vodium-test.appspot.com',
  messagingSenderId: '526495927231',
  appId: '1:526495927231:web:1e3e684f05d01c5a76ef0a',
};

/* 
  dev firebaseConfig
  apiKey: 'AIzaSyDxJhLxfHCMYfWpVrN19OCEtPnCCJepMOU',
  authDomain: 'vodium-dev.firebaseapp.com',
  projectId: 'vodium-dev',
  storageBucket: 'vodium-dev.appspot.com',
  messagingSenderId: '328115741898',
  appId: '1:328115741898:web:0698de0b3688684b7b4033',
*/

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const VODIUM_SIGN_IN = "https://vodium.com/sign-in-dev?hs_preview=LqALHaRZ-124026855076";
export const VODIUM_AUTH_WEB_TOKEN = "VODIUM_AUTH_WEB_TOKEN";
export const VODIUM_WEB_APP = 'https://dev-lite.vodium.com/';
export const BASE_URL = 'https://dev-download.vodium.com';
export const VODIUM_SECRET_PASS = "vodium-WbWkUgtncq";

