import { SET_PROMPTER_TEXT } from '../actionConstants';

/**
 * Function name: setPrompterTextAction
 * Description: Action creator to set the prompter text in the Redux store.
 * @param {string} payload - The text to set for the prompter.
 * @returns {Object} - Redux action object with the type and payload.
 */
export const setPrompterTextAction = (payload) => {
  return {
    type: SET_PROMPTER_TEXT,
    payload,
  };
};
