import fetch from 'node-fetch';
import { BASE_URL } from '../firebase-config';

/**
 * Function name: activateKeyService
 * Description: Activates a key in the server.
 * @param {String} inputKey - The input key to activate.
 * @param {String} fingerprint - The fingerprint to verify the key.
 * @returns {Promise<object>} - The response object.
 */
export const activateKeyService = async (inputKey, fingerprint) => {
  try {
    const response = await fetch(
      BASE_URL + '/keys/app-activate-key/' + inputKey,
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          activated: true,
          fingerprint: fingerprint,
        }),
      }
    );
    let data = await response.json();
    console.log('{keycheck} API app-activate-key response', data.message);
    return data;
  } catch (error) {
    throw new Error(`Error in activateKeyService: ${error}`);
  }
};

/**
 * Function name: licenseConfigurationPOST
 * Description: Sends user configuration data to the server.
 * @param {String} email - The user's email.
 * @param {String} fontSize - The preferred font size.
 * @param {String} scrollSpeed - The preferred scroll speed.
 * @returns {Promise<object>} - The response object.
 */
export const licenseConfigurationPOST = async (
  email,
  fontSize,
  scrollSpeed
) => {
  try {
    const response = await fetch(BASE_URL + '/user-configuration', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email,
        settings: JSON.stringify({
          SCROLL_SPEED: scrollSpeed,
          FONT_SIZE: fontSize,
        }),
      }),
    });
    let data = await response.json();
    return data;
  } catch (error) {
    throw new Error(`Error in activateKeyService: ${error}`);
  }
};

/**
 * Function name: licenseConfigurationGET
 * Description: Gets user configuration data from the server.
 * @param {String} keyString - The user's key.
 * @returns {Promise<object>} - The response object.
 */
export const licenseConfigurationGET = (keyString) => {
  try {
    let queryParam = `email=${keyString}`;
    return new Promise((resolve, reject) => {
      fetch(`${BASE_URL}/user-configuration?${queryParam}`, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },  
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('API request failed');
          }
          return response.json();
        })
        .then((keyData) => {
          resolve(keyData);
        })
        .catch((error) => {
          console.log('API call error', error);
          reject(error);
        });
    }).catch((error) => {
      console.log('Fetch error', error);
    });
  } catch (error) {
    console.log(error);
  }
};

/**
 * Function name: userAuthentication
 * Description: Authenticates the user using a token.
 * @param {String} token - The user's token.
 * @returns {Promise<string>} - The user's email.
 */
export const userAuthentication = async (token) => {
  const queryParam = `tokenId=${token}`;

  try {
    const response = await fetch(`${BASE_URL}/user/email?${queryParam}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('API request failed');
    }

    const email = await response.text();
    return email;
  } catch (error) {
    console.log('API call error', error);
    throw error;
  }
};

