import { SET_IS_IN_TEAMS } from '../actionConstants';

/**
 * Function name: setIsInTeamsAction
 * Description: Action creator to set the 'is in teams' state.
 * @param {boolean} payload - The value to set for the 'is in teams' state.
 * @returns {Object} - Redux action object with the type and payload.
 */
export const setIsInTeamsAction = (payload) => {
  return {
    type: SET_IS_IN_TEAMS,
    payload,
  };
};
