import { SET_PRESENT_MODE } from '../actionConstants';

/**
 * Function name: setPresentModeAction
 * Description: Action creator to set the 'present mode' state in the Redux store.
 * @param {boolean} payload - The value to set for the 'present mode' state.
 * @returns {Object} - Redux action object with the type and payload.
 */
export const setPresentModeAction = (payload) => {
  return {
    type: SET_PRESENT_MODE,
    payload,
  };
};
