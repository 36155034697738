import { SET_FILE_TEXT } from '../actionConstants';

/**
 * Function name: setFileTextAction
 * Description: Action creator to set the file text in the Redux store.
 * @param {string} payload - The text to set for the file.
 * @returns {Object} - Redux action object with the type and payload.
 */
export const setFileTextAction = (payload) => {
  return {
    type: SET_FILE_TEXT,
    payload,
  };
};
