import { SET_PROMPTER_FONT_SIZE } from '../actionConstants';

/**
 * Function name: setPrompterFontSizeAction
 * Description: Action creator to set the font size of the prompter in the Redux store.
 * @param {number} payload - The font size to set for the prompter.
 * @returns {Object} - Redux action object with the type and payload.
 */
export const setPrompterFontSizeAction = (payload) => {
  return {
    type: SET_PROMPTER_FONT_SIZE,
    payload,
  };
};
