import { SET_DARK_MODE } from '../actionConstants';

/**
 * Function name: setDarkModeAction
 * Description: Creates an action to set the 'dark mode' state in the Redux store.
 * @param {boolean} payload - The value to set for the 'dark mode' state.
 * @returns {Object} - The action object.
 */
export const setDarkModeAction = (payload) => {
  return {
    type: SET_DARK_MODE,
    payload,
  };
};
